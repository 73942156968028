/*
 |-----------------------------------------------------------------------------
 | hooks/useResend.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { useRef, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import type { TUseResendParams } from './types';

const useResend = ({ action, title }: TUseResendParams) => {
	const router = useRouter();

	const turnstileRef = useRef();

	const [isInvalid, setIsInvalid] = useState<boolean>(false);
	const [turnstileStatus, setTurnstileStatus] = useState<string>('');
	const [turnstileToken, setTurnstileToken] = useState<string>('');

	const {
		control: rhfControl,
		formState: { errors, isSubmitting },
		handleSubmit,
		register,
		reset,
	} = useForm({
		defaultValues: {
			form: title,
		},
		mode: 'onBlur',
	});

	const onError = () => {
		setIsInvalid(true);
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const onSubmit: SubmitHandler<any> = (data, e) => {
		e?.preventDefault();

		if (turnstileStatus === 'solved' && turnstileToken) {
			fetch('/api/send', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			})
				.then(async () => {
					setTurnstileStatus('');
					// @ts-expect-error TODO
					turnstileRef.current?.reset();
					await router.push(action);
					reset();
				})
				.catch(err => {
					// @ts-expect-error TODO
					turnstileRef.current?.reset();
					Sentry.captureException(err);
				});
		} else {
			console.error('Some error');
			// @ts-expect-error TODO
			turnstileRef.current?.reset();
		}
	};

	return {
		errors,
		handleSubmit,
		isInvalid,
		isSubmitting,
		onSubmit,
		onError,
		query: router.query,
		register,
		rhfControl,
		setTurnstileStatus,
		setTurnstileToken,
		turnstileRef,
		turnstileStatus,
	};
};

export { useResend };
